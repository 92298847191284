html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

@media only screen and (min-width: 50em) {
  .two-col {
    float: left;
    width: calc((100% - 2.1rem) / 2);
    margin: 0 0 0 2.1rem;
    padding-right: 2.1rem;
    border-right: 1px solid #eee;
    padding-bottom: 99999px;
    margin-bottom: -99999px; }
    .two-col:nth-child(2n+1) {
      margin-left: 0; } }

.two-col:last-child {
  padding-right: 0;
  border-right: none; }

.content-column {
  line-height: 1.7; }
  .content-column .title {
    font-size: 1.5rem; }
  .content-column p {
    margin: 0 0 2rem; }

.news-items h2 {
  font-size: 1.5rem; }

.news-items .news {
  padding: 0;
  border-bottom: none;
  margin: 0; }
  .news-items .news .title {
    margin-bottom: 0.45rem;
    padding-left: 1.95rem;
    padding-bottom: 0.75rem;
    font-size: 1.1rem;
    background: url("/images/backgrounds/icon_news.gif") no-repeat left 6px;
    background-size: 26px; }
    @media only screen and (min-width: 70em) {
      .news-items .news .title {
        padding-bottom: 0; } }
  .news-items .news .datetime {
    padding-left: 1.95rem;
    font-style: italic;
    font-size: 1rem; }
    .news-items .news .datetime:before {
      content: none; }
  .news-items .news .meta {
    display: none; }

.news-items .morelink {
  float: right; }
