
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

.two-col {
	
	@media #{$tablet} {
		@include fraction(2, $block-padding*1.4);
		padding-right: ($block-padding*1.4);
		border-right: 1px solid $color-lightgrey;
		padding-bottom: 99999px;
		margin-bottom: -99999px;
	}

	&:last-child {
		padding-right: 0;
		border-right: none;
	}
}

.content-column {
	line-height: 1.7;

	.title {
		font-size: $h3;
	}

	p {
		margin: 0 0 $paragraph-margin;
	}
}

// news items
.news-items {

	h2 {
		font-size: $h3;
	}

	.news {
		padding: 0; 
    	border-bottom: none;
    	margin: 0;

		.title {
			margin-bottom: ($block-padding*0.3);
			padding-left: ($block-padding*1.3);
			padding-bottom: ($block-padding*0.5);
			font-size: $h6;
			background: url('/images/backgrounds/icon_news.gif') no-repeat left 6px;
			background-size: 26px;

			@media #{$laptop} {
				padding-bottom: 0;
			}
		}

		.datetime {
			padding-left: ($block-padding*1.3);
			font-style: $font-italic;
			font-size: ($font-size-body - 0.1);

			&:before {
				content: none;
			}
		}

		.meta {
			display: none;
		}		
	}

	.morelink {
		float: right;
	}
}